@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: 'Roboto', sans-serif;
  background-color: #858484;
}
/*------------global-----------*/
/*------------global-----------*/
.container {
  max-width: 85%;
  margin: auto;
}
.flexSB {
  display: flex;
  justify-content: space-between;
}
.icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.3);
  color: #1eb2a6;
  transition: 0.5s;
}
.icon:hover {
  cursor: pointer;
  background-color: #1eb2a6;
  color: #fff;
}
a {
  text-decoration: none;
}
li {
  list-style-type: none;
}
.row {
  width: 50%;
}
button {
  padding: 17px 30px;
  background-color: #fff;
  color: #1eb2a6;
  font-weight: 600;
  border: none;
  margin: 30px 10px 0 0;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0 24px 36px -11px rgb(0 0 0 / 9%);
  transition: 0.5s;
}
button i {
  margin-left: 20px;
}
button:hover {
  box-shadow: none;
}
.primary-btn {
  background-color: #1eb2a6;
  color: #fff;
}

/*------------global-----------*/
#heading {
  text-align: center;
  padding: 40px 0;
}
#heading h3 {
  font-weight: 600;
  letter-spacing: 1px;
  color: #1eb2a6;
  text-transform: uppercase;
}
#heading h1 {
  font-size: 45px;
  margin: 20px 0;
  text-transform: capitalize;
}
p {
  line-height: 30px;
  font-size: 18px;
}
.back {
  background-image: url('../public/images/a.jpeg');
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100vh;
  padding-top: 20%;
  padding-right: 50px;
  color: black;
  text-align: center;
}
.back h1 {
  font-size: 100px;
  font-weight: 100;
}
.back h2 {
  font-weight: 500;
  font-size: 17px;
  text-transform: uppercase;
}
.margin {
  margin-top: 40.3%;
}
.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
}
.flex {
  display: flex;
}
.grid2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}
.outline-btn {
  margin: 0;
  box-shadow: none;
  border: 2px solid #1eb2a6;
  width: 100%;
  transition: 0.5s;
}
.outline-btn:hover {
  background-color: #1eb2a6;
  color: #fff;
}
.grid3 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
}
.padding {
  padding: 80px 0;
}
.shadow {
  box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%);
  background-color: #fff;
}
@media screen and (max-width: 768px) {
  .grid2,
  .grid3,
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .back {
    background-position: center;
    padding-top: 50%;
    height: 80vh;
  }
}

.back h1 {
  font-size: 100px;
  font-weight: 400;
}
.back h2 {
  font-weight: 500;
  font-size: 17px;
  text-transform: uppercase;
}
.margin {
  margin-top: 40.3%;
}
.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
}
.flex {
  display: flex;
}
.grid2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}
.outline-btn {
  margin: 0;
  box-shadow: none;
  border: 2px solid #1eb2a6;
  width: 100%;
  transition: 0.5s;
}
.outline-btn:hover {
  background-color: #1eb2a6;
  color: #fff;
}
.grid3 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
}
.padding {
  padding: 80px 0;
}
.shadow {
  box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%);
  background-color: #fff;
}
@media screen and (max-width: 768px) {
  .grid2,
  .grid3,
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .back {
    background-position: center;
    padding-top: 50%;
    height: 80vh;
  }
}

.mtop {
  margin-top: 50px;
}
h3 {
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  color: black;
  margin: 20px 0;
}
.heading h1 {
  font-size: 30px;
  margin-bottom: 30px;
}
.about p {
  margin-bottom: 20px;
}
.about .left {
  padding-right: 30px;
}

.about .img {
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.about img {
  object-fit: contain;
}
.about .img::after {
  content: '';
  position: absolute;
  top: 0;
  left: -50px;
  right: 0;
  background-color: #000;
  width: 600px;
  height: 56vh;
  z-index: -1;
}

.Services {
  text-align: center;
  background-color: #f8f9fe;
  padding: 50px 0;
}
.Services .box {
  text-align: left;
}
.Services img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.Services .text {
  background: #fff;
  box-shadow: 0 5px 20px 0 rgb(0 0 0 / 6%);
  padding: 30px;
  margin: 20px;
  margin-top: -30px;
  position: relative;
  z-index: 1;
}
.Services .text h2 {
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 20px;
}
.left,
.right {
  width: 50%;
  position: relative;
}
.flex {
  display: flex;
}
.topMarign {
  margin-top: 80px;
}
